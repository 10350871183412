import {core} from "../index";

export const updateConfirmation = (values) => (dispatch) => {
	dispatch({ type: "LOADING_CONFIRMATION", payload: true });

	core
		.post("/api/public/confirmation", values)
		.then((r) => {
			dispatch({
				type: "SET_CONFIRMATION_ERROR",
				payload: r.data.status === "error",
			});
			dispatch({
				type: "SET_CONFIRMATION_SUCCESS",
				payload: r.data.status === "success",
			});
		})
		.finally(() => {
			dispatch({ type: "LOADING_CONFIRMATION", payload: false });
		});
};

export const getConfirmation = (values) => (dispatch) => {
	dispatch({ type: "LOADING_CONFIRMATION", payload: true });

	if (values.tokenId.length !== 9) {
		dispatch({ type: "SET_CONFIRMATION_ERROR", payload: true });
	} else
		core
			.get("/api/public/confirmation", { params: values })
			.then((r) => {
				if (r.data.is_confirmed || r.data.status === "success") {
					dispatch({ type: "SET_CONFIRMATION_SUCCESS", payload: true });
				}
					dispatch({ type: "SET_CONFIRMATION_DATA", payload: r.data });

			})
			.catch(() => {
				dispatch({ type: "SET_CONFIRMATION_ERROR", payload: true });
			})
			.finally(() => {
				dispatch({ type: "LOADING_CONFIRMATION", payload: false });
			});
};
