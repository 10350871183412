import img1 from "./hero_img_1.png";
import img2 from "./hero_img_2.png";
import img3 from "./hero_img_3.png";
import img4 from "./hero_img_4.png";
import img5 from "./hero_img_5.png";

const PolygonStyle = {
	clipPath:
		"polygon(74.1% 44.1%, 100% 50%, 90% 10%, 80% 0%, 70% 5%, 60% 30%, 50% 60%, 40% 70%, 30% 60%, 20% 40%, 10% 70%, 0% 60%, 10% 90%, 20% 80%, 70% 100%, 74.1% 44.1%)",
};

const HeroSection = () => {
	return (
		<section>
			<div className="relative isolate">
				<div className="overflow-hidden">
					<div className="mx-auto max-w-7xl px-6 pb-32 lg:px-8 ">
						<div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
							<div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
								<h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl">
									Streamline Your Shipping, Simplify Your Logistics
								</h1>
								<p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
									Discover Seagoat App – your ultimate solution for seamless
									shipping and order management. Our intuitive platform
									integrates logistics and tracking, making every shipment
									smooth and efficient. Whether you're a small business or a
									large enterprise, elevate your logistics with Seagoat App
									today.
								</p>
								<div className="mt-10 flex items-center gap-x-6">
									<a
										href="/pricing"
										className="rounded-md px-3.5 py-2.5 text-sm hover:text-white font-semibold text-white shadow-sm bg-sky-700 hover:bg-sky-500"
									>
										Join now
									</a>
									<div
										className="text-sm font-semibold leading-6 text-gray-900"
									>
										Live demo <span aria-hidden="true">→</span>
									</div>
								</div>
							</div>
							<div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
								<div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
									<div className="relative">
										<img
											src={img1}
											alt=""
											className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
										/>
										<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
									</div>
								</div>
								<div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
									<div className="relative">
										<img
											src={img2}
											alt=""
											className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
										/>
										<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
									</div>
									<div className="relative">
										<img
											src={img4}
											alt=""
											className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
										/>
										<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
									</div>
								</div>
								<div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
									<div className="relative">
										<img
											src={img3}
											alt=""
											className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
										/>
										<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
									</div>
									<div className="relative">
										<img
											src={img5}
											alt=""
											className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
										/>
										<div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
									</div>
								</div>
							</div>
							<div
								className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
								aria-hidden="true"
							>
								<div
									className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#80b5ff] to-[#9089fc] opacity-30"
									style={PolygonStyle}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
