import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getConfirmation, updateConfirmation} from "./confirmation.service";
import {useParams} from "react-router";
import moment from "moment/moment";
import ConfirmationSuccess from "./ConfirmationSuccess";
import ConfirmationError from "./ConfirmationError";
import ConfirmationLoading from "./ConfirmationLoading";
import ConfirmationForm from "./ConfirmationForm";
import ConfirmationLayout from "./ConfirmationLayout";
import ConfirmationFormLayout from "./ConfirmationForm/ConfirmationFormLayout";

const Confirmation = ({ getConfirmation }) => {
	const { tokenId } = useParams();

	useEffect(() => {
		getConfirmation({ tokenId });
	}, [getConfirmation, tokenId]);

	const data = useSelector((state) => state.confirmation.data);

	const [formValues, setFormValues] = useState({
		date: "",
		address: "",
		message: "",
		title: "",
		time: ""
	});

	useEffect(() => {
		if (data) {
			const getDateTime = (info) =>
				info.operation?.route?.date
					? moment(info.operation.route.date).format("ddd DD / MMM / YYYY")
					: "";

			const getPostcode = (info) => info.operation?.station?.postcode ?? "";
			const getAddress = (info) => info.operation?.station?.address_line_1 ?? "";

			setFormValues({
				date: getDateTime(data.info),
				address: `${getPostcode(data.info)} ${getAddress(data.info)}`,
				message: "",
				title: data.info?.operation?.order?.title ?? "",
				time: data.info?.operation?.time_agreed ?? ""
			});
		}
	}, [data]);
	console.log({
		data
	})

	return (
		<ConfirmationLayout>
			<ConfirmationFormLayout>
				<ConfirmationError>
					<ConfirmationSuccess formValues={formValues}>
						<ConfirmationLoading>
							<div className="mx-auto max-w-xl lg:max-w-4xl">
								<h2 className="text-5xl text-center font-bold tracking-tight text-gray-900">
									Confirmation Required
								</h2>
								<p className="mt-2 text-xl leading-8 text-gray-600 font-semibold">
									We just want to make sure we've got everything right. Please
									confirm your details below.
								</p>
							</div>
							<ConfirmationForm formValues={formValues} setFormValues={setFormValues}/>
						</ConfirmationLoading>
					</ConfirmationSuccess>
				</ConfirmationError>
			</ConfirmationFormLayout>
		</ConfirmationLayout>
	);
};

const mapStateToProps = (state) => ({
	loading: state.confirmation.loading,
	data: state.confirmation.data,
	error: state.confirmation.error,
	success: state.confirmation.success,
});

const mapDispatchToProps = (dispatch) => ({
	updateConfirmation: (values) => dispatch(updateConfirmation(values)),
	getConfirmation: (token) => dispatch(getConfirmation(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
