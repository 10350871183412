import React, { useState } from "react";
import AnimatedInputProxy from "../../../../helpers/forms/AnimatedInputProxy";

const CustomerDetailsStage = ({ goNext, goBack, setFormValues, formValues, itemKey }) => {
	const [fieldErrors, setFieldErrors] = useState({
		full_name: false,
	});

	const handleFromValues = (values) => {
		const { customer_details } = formValues;

		const newFormValues = {
			...formValues,
			customer_details: { ...customer_details, ...values },
		};

		setFormValues(newFormValues);
	};

	const handleGoNext = () => {
		// const errors = handleStageValidation();
		// if (!errors.mobile && !errors.email && !errors.full_name) {
		goNext();
		// }
	};

	// const handleStageValidation = () => {
	// 	const newFieldErrors = {};
	// 	newFieldErrors.full_name = !formValues?.customer_details?.full_name;
	// 	newFieldErrors.mobile = !formValues?.customer_details?.mobile;
	// 	newFieldErrors.email = !formValues?.customer_details?.email;

	// 	setFieldErrors(newFieldErrors);
	// 	return newFieldErrors;
	// };

	return (
		<>
			<div className="border-t border-gray-900/10">
				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
					<div className="col-span-full sm:col-span-3">
						<AnimatedInputProxy
							{...{
								name: "full_name",
								value: formValues?.customer_details?.full_name,
								label: (
									<>
										Full Name <span className="text-red-500">*</span>
									</>
								),
								onChange: (e) => {
									setFieldErrors({...fieldErrors, ...{full_name: false}});
									handleFromValues({full_name: e.target.value});
								},
							}}
						/>
						{fieldErrors.full_name && (
							<p className="ml-4 font-semibold text-red-500">Required field</p>
						)}
					</div>
				</div>
			</div>

			<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				<div className="col-span-full sm:col-span-3 mt-2">
					<AnimatedInputProxy
						{...{
							name: "mobile",
							value: formValues?.customer_details?.mobile,
							label: (
								<>
									Mobile <span className="text-red-500">*</span>
								</>
							),
							onChange: (e) => {
								setFieldErrors({...fieldErrors, ...{mobile: false}});
								handleFromValues({mobile: e.target.value});
							},
						}}
					/>
					{fieldErrors.mobile && (
						<p className="ml-4 font-semibold text-red-500">Required field</p>
					)}
				</div>
			</div>

			<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				<div className="col-span-full sm:col-span-3 mt-2">
					<AnimatedInputProxy
						{...{
							name: "landline",
							value: formValues?.customer_details?.landline,
							label: <>Landline</>,
							onChange: (e) => {
								handleFromValues({landline: e.target.value});
							},
						}}
					/>
					{fieldErrors.landline && (
						<p className="ml-4 font-semibold text-red-500">Required field</p>
					)}
				</div>
			</div>

			<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				<div className="col-span-full sm:col-span-3 mt-2">
					<AnimatedInputProxy
						{...{
							name: "email",
							label: (
								<>
									Email <span className="text-red-500">*</span>
								</>
							),
							onChange: (e) => {
								handleFromValues({email: e.target.value});
								setFieldErrors({...fieldErrors, ...{email: false}});
							},
						}}
					/>
					{fieldErrors.email && (
						<p className="ml-4 font-semibold text-red-500">Required field</p>
					)}
				</div>
			</div>

			<div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
				{itemKey !== 0 && <button
					onClick={goBack}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
				>
					Back
				</button>}
				<button
					onClick={handleGoNext}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
					Next
				</button>
			</div>
		</>
	);
};

export default CustomerDetailsStage;
