import { core } from "../../../index";
import {
	loadingAuth,
	loadingCabinet,
	setAuthResponse,
	setAuthUser,
} from "../../../cabinet/util.reducer";

export const initPasswordReset =
	({ email }, navigate) =>
	(dispatch) => {
		dispatch(loadingCabinet(true));

		core
			.post("/api/init-password-reset", { email })
			.then((r) => {
				if (r.data.stage === "password_reset_confirm") {
					navigate(`/forgot-password-confirm/${email}`);
				}
				dispatch(setAuthResponse(r.data));
			})
			.catch((e) => {
				if (e.response?.data?.message) {
					dispatch(setAuthResponse(e.response.data));
				}
			})
			.finally(() => {
				dispatch(loadingCabinet(false));
			});
	};

export const confirmPasswordReset =
	({ email, password, code }, navigate) =>
	(dispatch) => {
		dispatch(loadingCabinet(true));

		core
			.post("/api/confirm-password-reset", { email, password, code })
			.then((r) => {
				dispatch(setAuthResponse(r.data));
				navigate("/login");
			})
			.catch((e) => {
				dispatch(setAuthResponse(e.response.data));
			})
			.finally(() => {
				dispatch(loadingCabinet(false));
			});
	};

export const login =
	({ email, password }) =>
	(dispatch) => {
		dispatch(loadingCabinet(true));

		core.get("/sanctum/csrf-cookie").then(() => {
			core
				.post(
					"/api/login",
					{ email, password },
					{
						withCredentials: true,
					}
				)
				.then((r) => {
					if (r.data.redirect_to) {
						// eslint-disable-next-line no-restricted-globals
						if (location.search.split("?r=").length === 2) {
							// eslint-disable-next-line no-restricted-globals
							window.location.href = decodeURL(location.search.split("?r=")[1]);
						} else {
							window.location.href = r.data.redirect_to;
						}
					}
				})
				.catch((e) => {
					dispatch(setAuthResponse(e.response.data));
					dispatch(setAuthUser(null));
				})
				.finally(() => {
					dispatch(loadingCabinet(false));
				});
		});
	};

function decodeURL(encodedURL) {
	// Decode the URL using decodeURIComponent
	const decodedURL = decodeURIComponent(encodedURL);

	return atob(decodedURL);
}
export const passwordChange =
	({ password, session, username }, navigate) =>
	(dispatch) => {
		dispatch(loadingCabinet(true));
		core
			.post("/api/password-change", { password, session, username })
			.then((r) => {
				dispatch(
					setAuthResponse({
						message: r.data.nessage,
						status: "end",
					})
				);
				navigate("/login");
			})
			.catch(() => {})
			.finally(() => {});
	};

export const getCurrentUser = () => (dispatch) => {
	dispatch(loadingAuth(true));
	core
		.get(`/api/getCurrentUser`)
		.then((response) => {
			if (response.data.user) {
				dispatch(
					setAuthUser({
						...response.data.user,
						redirect_to: response.data.redirect_to,
					})
				);
				// if (
				// response.data?.redirect_to &&
				// response.data.redirect_to !== window.location.href
				// ) {
				// window.location.href = response.data.redirect_to;
				// }
			}
		})
		.catch(() => {})
		.finally(() => dispatch(loadingAuth(false)));
};

export const isLoggedIn = () => (dispatch) => {
	dispatch(loadingCabinet(true));

	core
		.get("/api/getCurrentUser")
		.then((response) => {
			if (!response.data.user) {
				window.href.location = "/login";
			} else {
				dispatch(setAuthUser(response.data.user));
			}
		})
		.finally(() => {
			dispatch(loadingCabinet(false));
		});
};

export const logout = () => {
	core.post("/api/logout").then((response) => {
		window.location.href = response.data.redirect_to;
	});
};
