import { useDispatch, useSelector } from "react-redux";
import { submitRequest } from "../../../services/requestService";
import {useParams} from "react-router";

const ConfirmationItemStage = ({ formValues }) => {
	const floors = useSelector((state) => state.request.formData?.floors);
	const parkingSpots = useSelector((state) => state.request.formData?.parkingSpots);
	const dispatch = useDispatch();
	const {tokenId} = useParams();

	const handleSubmit = () => {
		formValues.tokenId = tokenId;
		if(formValues.collection?.house_nr) {
			formValues.collection.address= `${formValues.collection.house_nr} ${formValues.collection.address}`
		}

		if(formValues.delivery?.house_nr) {
			formValues.delivery.address= `${formValues.delivery.house_nr} ${formValues.delivery.address}`
		}

		dispatch(submitRequest(formValues));
	};

	return (
		<div className="max-w-3xl mx-auto mt-10  p-6">
			{/* Heading */}
			<div className="mb-8 text-center">
				<h2 className="text-2xl font-bold text-gray-800">
					Please review your information before submitting
				</h2>
				<p className="text-gray-600 mt-2">
					Ensure all details are correct before proceeding.
				</p>
			</div>

			{/* Customer Details */}
			{formValues.customer_details && (
				<div className="mb-8">
					<h3 className="text-xl font-semibold text-gray-900 mb-4">Customer Details</h3>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
						{formValues.customer_details.full_name && (
							<div>
								<dt className="text-sm font-medium text-gray-700">Full Name</dt>
								<dd className="text-base text-gray-900">{formValues.customer_details.full_name}</dd>
							</div>
						)}
						{formValues.customer_details.email && (
							<div>
								<dt className="text-sm font-medium text-gray-700">Email Address</dt>
								<dd className="text-base text-gray-900">{formValues.customer_details.email}</dd>
							</div>
						)}
						{formValues.customer_details.mobile && (
							<div>
								<dt className="text-sm font-medium text-gray-700">Mobile</dt>
								<dd className="text-base text-gray-900">{formValues.customer_details.mobile}</dd>
							</div>
						)}
						{formValues.customer_details.landline && (
							<div>
								<dt className="text-sm font-medium text-gray-700">Landline</dt>
								<dd className="text-base text-gray-900">{formValues.customer_details.landline}</dd>
							</div>
						)}
					</div>
				</div>
			)}

			{/* Collection Details */}
			<div className="mb-8">
				<h3 className="text-xl font-semibold text-gray-900 mb-4">Collection Details</h3>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
					{formValues.collection?.address && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Address</dt>
							<dd className="text-base text-gray-900">{`${formValues.collection.house_nr} ${formValues.collection.address}`}</dd>
						</div>
					)}
					{formValues.collection?.floor && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Floor</dt>
							<dd className="text-base text-gray-900">
								{floors?.find((f) => f.id === parseInt(formValues.collection.floor))?.title}
							</dd>
						</div>
					)}
					{formValues.collection?.parking_spot && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Parking Spot</dt>
							<dd className="text-base text-gray-900">
								{parkingSpots?.find((f) => f.id === parseInt(formValues.collection.parking_spot))?.title}
							</dd>
						</div>
					)}
				</div>
			</div>

			{/* Delivery Details */}
			<div className="mb-8">
				<h3 className="text-xl font-semibold text-gray-900 mb-4">Delivery Details</h3>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
					{formValues.delivery?.address && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Address</dt>
							<dd className="text-base text-gray-900">{`${formValues.delivery.house_nr} ${formValues.delivery.address}`}</dd>

						</div>
					)}
					{formValues.delivery?.floor && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Floor</dt>
							<dd className="text-base text-gray-900">
								{floors?.find((f) => f.id === parseInt(formValues.delivery.floor))?.title}
							</dd>
						</div>
					)}
					{formValues.delivery?.parking_spot && (
						<div>
							<dt className="text-sm font-medium text-gray-700">Parking Spot</dt>
							<dd className="text-base text-gray-900">
								{parkingSpots?.find((f) => f.id === parseInt(formValues.delivery.parking_spot))?.title}
							</dd>
						</div>
					)}
				</div>
			</div>

			{/* Items */}
			{formValues.items  && (
				<div className="mb-8">
					<h3 className="text-xl font-semibold text-gray-900 mb-4">Items</h3>
					<ul className="list-disc list-inside text-base text-gray-900">
						<li>{formValues.items}</li>
					</ul>
				</div>
			)}

			{/* Action Buttons */}
			<div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
				<button
					onClick={handleSubmit}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
					Submit Request
				</button>
				{/*<button*/}
				{/*	onClick={() => {*/}
				{/*		// Add your back/edit handler here*/}
				{/*	}}*/}
				{/*	type="button"*/}
				{/*	className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"*/}
				{/*>*/}
				{/*	Edit Information*/}
				{/*</button>*/}
			</div>
		</div>
	);
};

export default ConfirmationItemStage;
