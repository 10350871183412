import { useDispatch } from "react-redux";
import {useEffect, useState} from "react";
import FormBanner from "./FormBanner";
import { useLocation, useParams } from "react-router";
import RequestFormV2 from "./RequestFormV2";
import LoadingView from "./LoadingView";
import ErrorView from "./ErrorView";
import SuccessView from "./SuccessView";
import { getRequestData } from "../services/requestService";
import {core} from "../../index";

const Request = () => {
	const { fqdn } = useParams();
	const { search } = useLocation();
	const dispatch = useDispatch();
	const [isValid, setIsValid] = useState(false);
	const {tokenId} = useParams();

	useEffect(() => {
		core.post(`api/public/request/token`, {tokenId})
			.then(r=> setIsValid(r.data.status))
	}, [tokenId])


	useEffect(() => {
		document.title = "Request a quote | Delivery service";

		let query = null;
		if (search) {
			const searchParams = new URLSearchParams(search);
			query = searchParams.get("q");
		}

		dispatch(getRequestData(query, tokenId));
	}, [search, dispatch, tokenId]);
	if(!isValid) return null;
	return (
		<div>
			{fqdn && <FormBanner />}
			<div className={"pt-10"}>
				<LoadingView>
					<ErrorView>
						<SuccessView>
							<RequestFormV2 />
						</SuccessView>
					</ErrorView>
				</LoadingView>
			</div>
		</div>
	);
};

export default Request;
