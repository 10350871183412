import React, { useState } from "react";

const ItemsFormStageV3 = ({ setFormValues, goNext, formValues, goBack, itemKey }) => {
    const [inputValue, setInputValue] = useState("");


    const onChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFormValues = () => {
        setFormValues({ ...formValues, items: inputValue });
    };

    const handleGoNext = () => {
        handleFormValues();
        goNext();
    };

    return (
        <div className="relative">
            <div className="relative">
                <input
                    type="text"
                    id={"items"}
                    name={"items"}
                    onChange={onChange}
                    value={inputValue || ""}
                    className={`rounded-md peer w-full h-10 border-b-2 
                        border-gray-300 focus:outline-none has-value focus:border-sky-500`}
                    placeholder="Type your item here"
                />
            </div>

            {/*{showList && (*/}
            {/*    <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto transition duration-300 ease-in-out">*/}
            {/*        {showNewItem && (*/}
            {/*            <li*/}
            {/*                onClick={() => onClick({ id: null, title: inputValue })}*/}
            {/*                className="p-2 hover:bg-sky-500 hover:text-white cursor-pointer"*/}
            {/*            >*/}
            {/*                "{inputValue}" (new item)*/}
            {/*            </li>*/}
            {/*        )}*/}

            {/*        {filteredData.map((item, index) => (*/}
            {/*            <li*/}
            {/*                key={index}*/}
            {/*                onClick={() => onClick(item)}*/}
            {/*                className="p-2 hover:bg-sky-500 hover:text-white cursor-pointer"*/}
            {/*            >*/}
            {/*                {item.title}*/}
            {/*            </li>*/}
            {/*        ))}*/}
            {/*    </ul>*/}
            {/*)}*/}

            {/*/!* Display selected items with better spacing *!/*/}
            {/*<div className="mt-4 py-4 flex flex-wrap gap-x-3 gap-y-3">*/}
            {/*    {selection.map((item, index) => (*/}
            {/*        <span*/}
            {/*            key={index}*/}
            {/*            className="inline-flex items-center gap-x-0.5 rounded-md bg-sky-50 px-3 py-1 text-xs font-medium text-sky-700 ring-1 ring-inset ring-sky-600/10"*/}
            {/*        >*/}
            {/*            {item.title}*/}
            {/*            <button*/}
            {/*                type="button"*/}
            {/*                onClick={() => onClickRemove(item)}*/}
            {/*                className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-sky-600/20"*/}
            {/*            >*/}
            {/*                <span className="sr-only">Remove</span>*/}
            {/*                <svg*/}
            {/*                    viewBox="0 0 14 14"*/}
            {/*                    className="h-3.5 w-3.5 stroke-sky-600/50 group-hover:stroke-sky-600/75"*/}
            {/*                >*/}
            {/*                    <path d="M4 4l6 6m0-6l-6 6" />*/}
            {/*                </svg>*/}
            {/*                <span className="absolute -inset-1" />*/}
            {/*            </button>*/}
            {/*        </span>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
                {itemKey !== 0 && (
                    <button
                        onClick={goBack}
                        type="button"
                        className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                    >
                        Back
                    </button>
                )}
                <button
                    onClick={handleGoNext}
                    type="button"
                    className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ItemsFormStageV3;
