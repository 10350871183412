import React, { useEffect, useState } from "react";
import AnimatedSelectProxy from "../../../../helpers/forms/AnimatedSelectProxy";
import { useSelector } from "react-redux";
import AnimatedAutoCompleteProxy from "../../../../helpers/forms/AnimatedAutoCompleteProxy";
import AnimatedInputProxy from "../../../../helpers/forms/AnimatedInputProxy";

const YesNo = [
	{ id: "true", title: "Yes" },
	{ id: "false", title: "No" },
];

const CollectionFormStage = ({ setFormValues, formValues, goNext, goBack, itemKey }) => {
	const [showLift, setShowLift] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({
		address: false,
		floor: false,
		email: false,
	});
	const floors = useSelector((state) => state.request.formData?.floors);
	// const user = useSelector((state) => state.cabinet.utils.user);
	const profile = useSelector((state) => state.cabinet.utils.profile);

	const parkingSpots = useSelector(
		(state) => state.request.formData?.parkingSpots
	);

	const handleFromValues = (values) => {
		let newFormValues;

		if (Object.keys(values).length === 0) {
			newFormValues = {
				...formValues,
				collection: {},
			};
		} else {
			const { collection } = formValues;
			newFormValues = {
				...formValues,
				collection: { ...collection, ...values },
			};
		}

		setFormValues(newFormValues);
	};

	useEffect(() => {
		let flag = false;

		if (formValues?.collection?.floor) {
			// eslint-disable-next-line eqeqeq
			const listItem = floors.find((f) => f.id ===  parseInt(formValues.collection.floor));

			flag = !(listItem.title.includes("1") || listItem.title.includes("One") || listItem.title.includes("G"));
		}

		setShowLift(flag);
	}, [formValues, floors]);

	const handleGoNext = () => {
		// const errors = handleStageValidation();
		// if (!errors.address && !errors.floor) {
		goNext();
		// }
	};

	// const handleStageValidation = () => {
	// 	const newFieldErrors = {};
	// 	if (!formValues?.collection?.address) {
	// 		newFieldErrors.address = true;
	// 	}

	// 	if (!formValues?.collection?.floor) {
	// 		newFieldErrors.floor = true;
	// 	}

	// 	setFieldErrors(newFieldErrors);
	// 	return newFieldErrors;
	// };

	// const handleUseExisting = (e) => {
	// 	if (e.target.checked) {
	// 		const customerDetails = !profile
	// 			? formValues.customer_details
	// 			: {
	// 					full_name: user?.full_name,
	// 					mobile: profile?.mobile,
	// 					landline: profile?.landline,
	// 					email: profile?.email,
	// 					profile_id: profile.id,
	// 			  };
	//
	// 		handleFromValues(customerDetails);
	// 	} else {
	// 		handleFromValues({});
	// 	}
	// };

	const handleAddressChange = (profileAddressId) => {
		const pickedAddress = profile.addresses.find(
			// eslint-disable-next-line eqeqeq
			(address) => profileAddressId === address.id
		);

		handleFromValues({ address: pickedAddress.full_address });
	};

	return (
		<>
			{/*<div className="border-t border-gray-900/10 pb-4">*/}
			{/*	<div className="relative flex items-center justify-end mx-3 mt-4"></div>*/}

			{/*	<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
			{/*		<div className="col-span-full sm:col-span-3">*/}
			{/*			<AnimatedInputProxy*/}
			{/*				{...{*/}
			{/*					name: "full_name",*/}
			{/*					label: (*/}
			{/*						<>*/}
			{/*							Full Name <span className="text-red-500">*</span>*/}
			{/*						</>*/}
			{/*					),*/}
			{/*					value: formValues?.collection?.full_name ?? "",*/}
			{/*					onChange: (e) => {*/}
			{/*						setFieldErrors({ ...fieldErrors, ...{ full_name: false } });*/}
			{/*						handleFromValues({ full_name: e.target.value });*/}
			{/*					},*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*			{fieldErrors.full_name && (*/}
			{/*				<p className="ml-4 font-semibold text-red-500">Required field</p>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*		<div className="col-span-full sm:col-span-3">*/}
			{/*			{profile && (*/}
			{/*				<div className="flex h-6 items-center">*/}
			{/*					<input*/}
			{/*						id="use_existing"*/}
			{/*						onChange={(e) => handleUseExisting(e)}*/}
			{/*						// onClick={() => handleUseExisting()}*/}
			{/*						name="use_existing"*/}
			{/*						type="checkbox"*/}
			{/*						className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"*/}
			{/*					/>*/}
			{/*					<div className="ml-3 text-sm leading-6">*/}
			{/*						<label*/}
			{/*							htmlFor="use_existing"*/}
			{/*							className="font-medium text-gray-900"*/}
			{/*						>*/}
			{/*							Use existing customer details*/}
			{/*						</label>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
			{/*		<div className="col-span-full sm:col-span-3 mt-2">*/}
			{/*			<AnimatedInputProxy*/}
			{/*				{...{*/}
			{/*					name: "mobile",*/}
			{/*					value: formValues?.collection?.mobile ?? "",*/}
			{/*					label: (*/}
			{/*						<>*/}
			{/*							Mobile <span className="text-red-500">*</span>*/}
			{/*						</>*/}
			{/*					),*/}
			{/*					onChange: (e) => {*/}
			{/*						setFieldErrors({ ...fieldErrors, ...{ mobile: false } });*/}
			{/*						handleFromValues({ mobile: e.target.value });*/}
			{/*					},*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*			{fieldErrors.mobile && (*/}
			{/*				<p className="ml-4 font-semibold text-red-500">Required field</p>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
			{/*		<div className="col-span-full sm:col-span-3 mt-2">*/}
			{/*			<AnimatedInputProxy*/}
			{/*				{...{*/}
			{/*					name: "landline",*/}
			{/*					value: formValues?.collection?.landline ?? "",*/}
			{/*					label: <>Landline</>,*/}
			{/*					onChange: (e) => {*/}
			{/*						handleFromValues({ landline: e.target.value });*/}
			{/*					},*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*			{fieldErrors.landline && (*/}
			{/*				<p className="ml-4 font-semibold text-red-500">Required field</p>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
			{/*		<div className="col-span-full sm:col-span-3 mt-2">*/}
			{/*			<AnimatedInputProxy*/}
			{/*				{...{*/}
			{/*					name: "email",*/}
			{/*					value: formValues?.collection?.email ?? "",*/}
			{/*					label: (*/}
			{/*						<>*/}
			{/*							Email*/}
			{/*							/!*<span className="text-red-500">*</span>*!/*/}
			{/*						</>*/}
			{/*					),*/}
			{/*					onChange: (e) => {*/}
			{/*						handleFromValues({ email: e.target.value });*/}
			{/*						setFieldErrors({ ...fieldErrors, ...{ email: false } });*/}
			{/*					},*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*			{fieldErrors.email && (*/}
			{/*				<p className="ml-4 font-semibold text-red-500">Required field</p>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			<div className="pb-12">
				{profile?.addresses && (
					<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div className="col-span-full sm:col-span-3">
							Pick an address from your profile
						</div>
						<div className="col-span-full sm:col-span-3">
							<AnimatedSelectProxy
								{...{
									name: "saved_address",
									onChange: handleAddressChange,
									label: "Saved Addresses",
									options: profile.addresses.map((address) => ({
										id: address.id,
										title: address.name,
									})),
								}}
							/>
						</div>
					</div>
				)}
				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

					<div className="sm:col-span-5">
						<AnimatedAutoCompleteProxy {...{
							name: "address",
							label: (
								<>
									Collection Address <span className="text-red-500">*</span>
								</>
							),
							onChange: (e) => {
								setFieldErrors({...fieldErrors, ...{address: false}});
								handleFromValues({address: e.target.value});
							}
						}}
						/>
						{fieldErrors.address && (
							<p className="ml-4 font-semibold text-red-500">Required field</p>
						)}
					</div>
					<div className="sm:col-1">
						<AnimatedInputProxy {...{name: "house_nr", label: "House Nr", onChange: (e) => {
								handleFromValues({house_nr: e.target.value});
							}}} />
					</div>
				</div>

				<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
					<div className="sm:col-span-2">
							<AnimatedSelectProxy
								{...{
									label: "Is parking available?",
									options: parkingSpots,
									onChange: (e) => handleFromValues({parking_spot: e}),
								}}
							/>
						</div>
						<div className="sm:col-span-2">
							<AnimatedSelectProxy
								{...{
									label: (
										<>
											What floor are you on?{" "}
											<span className="text-red-500">*</span>
										</>
									),
									options: floors,
									onChange: (e) => {
										setFieldErrors({...fieldErrors, ...{floor: false}});
										handleFromValues({floor: e});
									},
								}}
							/>
							{fieldErrors.floor && (
								<p className="ml-4 font-semibold text-red-500">Required field</p>
							)}
							{showLift && (
								<div className="sm:mt-4 sm:ml-4">
									<label className="text-base text-gray-900">
										Is there lift available ?
									</label>
									<div className="space-x-4 flex flex-row">
										{YesNo.map((option) => (
											<div key={option.id} className="flex items-center">
												<input
													id={option.id}
													name="is-lift-available"
													type="radio"
													defaultChecked={"false"}
													onChange={(e) =>
														handleFromValues({
															is_lift_available: e.target.value,
														})
													}
													value={option.id}
													className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600"
												/>
												<label
													htmlFor={option.id}
													className="ml-3 block text-sm font-medium leading-6 text-gray-900"
												>
													{option.title}
												</label>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
						<div className="sm:col-span-2">
							<label className="text-base text-gray-900">
								Are there any entrance steps?
							</label>
							<div className="space-x-4 flex flex-row">
								{YesNo.map((option) => (
									<div key={option.id} className="flex items-center">
										<input
											id={option.id}
											name="is-entrence-with-steps"
											type="radio"
											value={option.id}
											defaultChecked={"false"}
											onChange={(e) =>
												handleFromValues({
													is_entrence_with_steps: e.target.value,
												})
											}
											className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-600"
										/>
										<label
											htmlFor={option.id}
											className="ml-3 block text-sm font-medium leading-6 text-gray-900"
										>
											{option.title}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Action Buttons */}
				<div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
				{itemKey !== 0 && <button
					onClick={goBack}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
				>
					Back
				</button>}
				<button
					onClick={handleGoNext}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
					Next
				</button>
			</div>
		</>
	);
};

export default CollectionFormStage;
