import moment from "moment";

const StatusBar = ({ status }) => {
	return (
		<div className="px-4 py-6 sm:px-6 lg:p-8">
			<h4 className="sr-only">Status</h4>
			<p className="text-xl font-bold text-gray-900"> {/* Increased font size */}
				{status.details.title} on{" "}
				<time dateTime={moment().format("YYYY-MM-DD")}>
					{moment().format("DD/MM/YYYY")}
				</time>
			</p>
			<div className="mt-6" aria-hidden="true">
				{/* Progress Bar */}
				<div className="overflow-hidden rounded-full bg-gray-200">
					<div
						className="h-4 rounded-full bg-green-600" // Increased height for visibility
						style={{ width: `${(status.step / 4) * 100}%` }}
					/>
				</div>
				{/* Status Labels */}
				<div className="mt-6 grid grid-cols-5 text-lg font-semibold text-gray-600 sm:grid hidden"> {/* Increased font for status labels */}
					<div className={`text-center ${status.step >= 0 ? "text-green-600" : ""}`}>
						Order Placed
					</div>
					<div className={`text-center ${status.step >= 1 ? "text-green-600" : ""}`}>
						Order Confirmed
					</div>
					<div className={`text-center ${status.step >= 2 ? "text-green-600" : ""}`}>
						Out for Collection
					</div>
					<div className={`text-center ${status.step >= 3 ? "text-green-600" : ""}`}>
						In Transit
					</div>
					<div className={`text-center ${status.step >= 4 ? "text-green-600" : ""}`}>
						Delivered
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatusBar;
