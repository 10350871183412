const TrackingBoardDestination = ({ title, data }) => {
    const containerStyles = "flex justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 w-72 max-w-none flex-none flex-col items-start";

    return (
        <div className={containerStyles}>
            <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
                {title}
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <div className="flex items-start text-lg font-semibold tracking-tight text-gray-900 mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2 flex-shrink-0"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <span>{data.time}</span>
                </div>
                <div className="flex items-start text-lg font-semibold tracking-tight text-gray-900 mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2 flex-shrink-0"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"/>
                    </svg>
                    <span>{data.date}</span>
                </div>
                <div className="flex items-start text-lg font-semibold tracking-tight text-gray-900">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2 flex-shrink-0"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
                    </svg>
                    <span>{data.address}</span>
                </div>
            </div>
        </div>
    );
};

export default TrackingBoardDestination;
