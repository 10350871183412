import {useSelector} from "react-redux";

const ConfirmationDestination = ({formValues}) => {
	const data = useSelector((state) => state.confirmation.data);

	return (
		<>
			<div className="px-4 pt-10 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dd className="mt-1 text-center text-4xl font-semibold leading-7 text-gray-700 sm:col-span-3 sm:mt-0">
					{formValues.title}
				</dd>
			</div>

			<div className="px-4 pt-5 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Date</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.date}
				</dd>
			</div>

			<div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Time</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.time}
				</dd>
			</div>

			<div className="px-4 pb-16 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Address</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.address}
				</dd>
			</div>

			{(data.show_one || data.show_ground) && (<section aria-labelledby="policies-heading" className={"pb-4"}>

				<dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
					{data.show_one && (<div className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
						<dt className="text-center">
								<span className="mt-4 text-lg font-medium text-gray-700">
									Please note, this job is booked for 1 driver
								</span>
						</dt>
						<dd className="mt-1 text-sm text-gray-500"></dd>
					</div>)}

					{data.show_ground && (<div className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
						<dt className="text-center">
								<span className="mt-4 text-lg font-medium text-gray-700">
									Please note, this is a ground floor job
								</span>
						</dt>
						<dd className="mt-1 text-sm text-gray-500"></dd>
					</div>)}
				</dl>


			</section>)}
		</>
	);
};

export default ConfirmationDestination;
