import {useNavigate, useParams} from "react-router";
import StatusBar from "./StatusBar";
import {useDispatch, useSelector} from "react-redux";
import {getTrackingData} from "./service";
import {useEffect} from "react";
import TrackingBoardDestination from "./TrackingBoardDestination";

const TrackingBoard = () => {
    const {tokenId} = useParams();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const tracking = useSelector((state) => state.tracking.info);

    useEffect(() => {
        if (tokenId) dispatch(getTrackingData(tokenId, navigate));
    }, [dispatch, navigate, tokenId]);

    if (!tracking) return null;

    return (<div className="mx-auto max-w-3xl">
            <div className="max-w-xl px-4">
                <p className="mt-2 text-4xl font-bold tracking-tight">
                    {tracking.status.details.title}
                </p>
                <p className="mt-2 text-base text-gray-500">
                    {tracking.status.details.description}
                </p>
            </div>
            <div className="mt-10 mx-w-xl">
                <p className={`text-2xl text-center font-medium text-gray-900`}>
                    {tracking.order.title}
                </p>
            </div>
            <StatusBar {...{status: tracking.status}} />
            <section
                aria-labelledby="order-heading"
                className="mt-10 pb-10 px-4"
            >

                <div
                    className="mx-auto mt-5 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-end">
                    <TrackingBoardDestination {...{
						title: 'Collection',
						data: tracking.collection,
						isActive: false
					}} />
                    <TrackingBoardDestination {...{
						title: 'Delivery',
						data: tracking.delivery,
						isActive: true
					}} />
                </div>
                {/*<div>*/}
                {/*	<dt className="font-semibold text-2xl text-gray-900">Collection</dt>*/}
                {/*	<dd className="mt-2 text-gray-700 text-xl">*/}
                {/*		<p>{tracking.collection?.address}</p>*/}
                {/*		<p>{tracking.collection?.date}</p>*/}
                {/*		<p>{tracking.collection?.time}</p>*/}
                {/*	</dd>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*	<dt className="font-semibold text-2xl text-gray-900">Delivery</dt>*/}
                {/*	<dd className="mt-2 text-gray-700 text-xl">*/}
                {/*		<p>{tracking.delivery?.address}</p>*/}
                {/*		<p>{tracking.delivery?.date}</p>*/}
                {/*		<p>{tracking.delivery?.time}</p>*/}
                {/*	</dd>*/}
                {/*</div>*/}
            </section>
        </div>);
};

export default TrackingBoard;
