import {
	ExclamationTriangleIcon,
	IdentificationIcon,
	MapPinIcon,
	WalletIcon,
} from "@heroicons/react/24/outline";
import AccordionItem from "./AccordionItem";
import CollectionFormStage from "./CollectionFormStage";
import { useEffect, useState } from "react";
import DeliveryFormStage from "./DeliveryFormStage";
import CustomerDetailsStage from "./CustomerDetailsFormStage";
import ConfirmationItemStage from "./ConfirmationItemStage";
import { useSelector } from "react-redux";
import ItemsFormStageV3 from "./ItemsFormStageV3";

const Accordion = () => {
	const [formValues, setFormValues] = useState({});
	const [items, setItems] = useState([

		{
			id: 2,
			title: "Collection",
			formKey: "collection",
			status: true,
			component: (props) => <CollectionFormStage {...{ ...props }} />,
			icon: <MapPinIcon className="-ml-0.5 h-5 w-5 mr-4" aria-hidden="true" />,
		},
		{
			id: 3,
			title: "Delivery",
			formKey: "delivery",
			status: null,
			component: (props) => <DeliveryFormStage {...{ ...props }} />,
			icon: <MapPinIcon className="-ml-0.5 h-5 w-5 mr-4" aria-hidden="true" />,
		},
		{
			id: 4,
			title: "Items",
			formKey: "items",
			status: null,
			component: (props) => <ItemsFormStageV3 {...{ ...props }} />,
			icon: <WalletIcon className="-ml-0.5 h-5 w-5 mr-4" aria-hidden="true" />,
		},
		// {
		// 	id: 1,
		// 	title: "Pricing Tier",
		// 	formKey: "tier",
		// 	status: null,
		// 	component: (props) => <PricingTier {...{ ...props }} />,
		// 	icon: <WalletIcon className="-ml-0.5 h-5 w-5 mr-4" aria-hidden="true" />,
		// },
		{
			id: 5,
			title: "Customer Details",
			formKey: "customer_details",
			status: null,
			component: (props) => <CustomerDetailsStage {...{ ...props }} />,
			icon: (
				<IdentificationIcon
					className="-ml-0.5 h-5 w-5 mr-4"
					aria-hidden="true"
				/>
			),
		},
		{
			id: 6,
			title: "Confirmation",
			status: null,
			component: (props) => <ConfirmationItemStage {...{ ...props }} />,
			icon: (
				<ExclamationTriangleIcon
					className="-ml-0.5 h-5 w-5 mr-4"
					aria-hidden="true"
				/>
			),
		},
	]);

	const user = useSelector((state) => state.cabinet.utils.user);
	const profile = useSelector((state) => state.request.formData.profile);

	useEffect(() => {
			if(profile) {
				setFormValues({customer_details: profile})
			}
	}, [profile])


	useEffect(() => {
		if (user) {
			const newItems = items.filter((i) => i.id !== 1);
			newItems[0].status = true;
			setItems(newItems);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const goNext = () => {
		const currentStage = items.findIndex((i) => i.status === true);
		const nextStage =
			currentStage + 1 > items.length ? currentStage : currentStage + 1;

		const newItems = [...items];

		newItems[currentStage].status = false;
		newItems[nextStage].status = true;

		setItems(newItems);
	};

	const goBack = () => {
		const currentStage = items.findIndex((i) => i.status === true);
		const previousStage =
			currentStage - 1 < 0 ? currentStage : currentStage - 1;

		const newItems = [...items];
		newItems[currentStage].status = false;
		newItems[previousStage].status = true;

		setItems(newItems);
	};


	return (
		<>
			<div className="overflow-hiddenw-full">
				<ul className="divide-y divide-gray-200">
					{items.map((item, key) => (
						<AccordionItem
							{...{ item, key, setFormValues, formValues, goNext, goBack, itemKey: key }}
						/>
					))}
				</ul>
			</div>
		</>
	);
};

export default Accordion;
